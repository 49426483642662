"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.minimumFulfilmentOrderSchema = exports.fulfilmentOrderSchema = exports.packingErrorReasonSchema = exports.FulfilmentOrderCancelRejectionReason = exports.FulfilmentOrderRejectionReason = exports.createFulfilmentOrderSchema = exports.packingModeSchema = exports.carrierName = exports.shippingMethod = exports.fulfilmentOrderStatusSchema = exports.fulfilmentOrderStatus = exports.FULFILMENT_ORDER_ENTITY_ID_PREFIX = void 0;
const z = __importStar(require("zod"));
const address_1 = require("../constants/address");
const base_1 = require("../events/base");
const date_1 = require("../events/date");
const packAndDispatch_1 = require("./packAndDispatch");
exports.FULFILMENT_ORDER_ENTITY_ID_PREFIX = 'FO';
exports.fulfilmentOrderStatus = {
    ACCEPTED: 'ACCEPTED',
    AWAITING_RATE_SELECTION: 'AWAITING_RATE_SELECTION',
    REJECTED: 'REJECTED',
    AWAITING_ASSIGNMENT: 'AWAITING_ASSIGNMENT',
    SELECTED_FOR_AUTOSTORE_HAND_PICKING: 'SELECTED_FOR_AUTOSTORE_HAND_PICKING',
    ASSIGNED_TO_TASK_GROUP: 'ASSIGNED_TO_TASK_GROUP',
    PICKING: 'PICKING',
    AUTOSTORE_HAND_PICKING: 'AUTOSTORE_HAND_PICKING',
    AWAITING_PACKING: 'AWAITING_PACKING',
    PACKING: 'PACKING',
    PACKED: 'PACKED',
    SHIPPED: 'SHIPPED',
    TROUBLESHOOTING: 'TROUBLESHOOTING',
    CANCELLED: 'CANCELLED',
};
exports.fulfilmentOrderStatusSchema = z.union([
    z.literal(exports.fulfilmentOrderStatus.ACCEPTED),
    z.literal(exports.fulfilmentOrderStatus.AWAITING_RATE_SELECTION),
    z.literal(exports.fulfilmentOrderStatus.REJECTED),
    z.literal(exports.fulfilmentOrderStatus.AWAITING_ASSIGNMENT),
    z.literal(exports.fulfilmentOrderStatus.SELECTED_FOR_AUTOSTORE_HAND_PICKING),
    z.literal(exports.fulfilmentOrderStatus.ASSIGNED_TO_TASK_GROUP),
    z.literal(exports.fulfilmentOrderStatus.PICKING),
    z.literal(exports.fulfilmentOrderStatus.AUTOSTORE_HAND_PICKING),
    z.literal(exports.fulfilmentOrderStatus.AWAITING_PACKING),
    z.literal(exports.fulfilmentOrderStatus.PACKING),
    z.literal(exports.fulfilmentOrderStatus.PACKED),
    z.literal(exports.fulfilmentOrderStatus.SHIPPED),
    z.literal(exports.fulfilmentOrderStatus.TROUBLESHOOTING),
    z.literal(exports.fulfilmentOrderStatus.CANCELLED),
]);
exports.shippingMethod = z.union([
    z.literal('STANDARD'),
    z.literal('SAMEDAY'),
]);
exports.carrierName = z.union([z.string(), z.literal('SKUTOPIA')]);
exports.packingModeSchema = z.literal('MANUAL'); // We can extend this to a union with CMC later
exports.createFulfilmentOrderSchema = z.object({
    id: z.string(),
    externalFulfilmentId: z.string().optional(),
    externalOrderReference: z.string().optional(),
    merchantId: z.string().min(1),
    merchantName: z.string().min(1).optional(),
    lineItems: z.array(z.object({
        barcode: z.string(),
        quantity: z.number(),
        isBundle: z.boolean().optional(),
        isDigital: z.boolean().optional(),
        sku: z.string().nullish(),
    })),
    customer: z.object({
        name: z.string().min(1).optional(),
        phone: z.string().min(1).optional(),
    }),
    address: z.optional(z.object({
        line1: z.string().nullish(),
        line2: z.string().nullish(),
        locality: z.string().nullish(),
        company: z.string().nullish(),
        countryCode: address_1.countryCodeSchema.nullish(),
        postalCode: z.string().nullish(),
    })),
    shippingMethod: exports.shippingMethod,
    carrierName: exports.carrierName.optional(),
    tags: z.array(z.string()).optional(),
    isB2B: z.boolean().optional(),
    packingMode: exports.packingModeSchema.optional(),
    store: z.optional(z.object({
        id: z.string(),
    })),
});
const availableQuantitySchema = z.object({
    barcode: z.string(),
    quantity: z.number(),
    availableQuantity: z.number(),
});
const insufficientStockInfoSchema = z.object({
    warehouse: z.object({ id: z.string() }),
    insufficientItems: z.array(availableQuantitySchema),
});
exports.FulfilmentOrderRejectionReason = z.union([
    z.object({
        type: z.literal('INVALID_REQUEST'),
        errors: z.record(z.string().array()).optional(),
    }),
    z.object({
        type: z.literal('UNKNOWN_BARCODES'),
        unknownBarcodes: z.array(z.string()),
    }),
    z.object({
        type: z.literal('BARCODES_MISSING'),
    }),
    z.object({
        type: z.literal('BARCODES_WITH_MULTIPLE_SKUS'),
        barcodes: z.string().array(),
    }),
    z.object({
        type: z.literal('WEIGHT_LIMIT_EXCEEDED'),
        weightLimitGm: z.number(),
    }),
    z.object({
        type: z.literal('VOLUME_LIMIT_EXCEEDED'),
        volumeLimitMm: z.number(),
    }),
    z.object({
        type: z.literal('INSUFFICIENT_STOCK'),
        insufficientInfo: z.array(insufficientStockInfoSchema),
    }),
    z.object({
        type: z.literal('FC_FULFILLABLE_DISABLED'),
    }),
    z.object({
        type: z.literal('INVALID_LINE_ITEM_QUANTITIES'),
    }),
    z.object({
        type: z.literal('NO_FULFILABLE_LINE_ITEMS_FOUND'),
    }),
    z.object({
        type: z.literal('NO_VALID_WAREHOUSES'),
    }),
    z.object({
        type: z.literal('RATES_RETRIEVAL_FAILED'),
    }),
    z.object({
        type: z.literal('RATE_SELECTION_FAILED'),
    }),
]);
exports.FulfilmentOrderCancelRejectionReason = z.literal('ORDER_ALREADY_ASSIGNED');
const manualPackingCancelReasonSchema = z.enum([
    'MISSING_ITEM',
    'EXCESS_ITEM',
    'INCORRECT_ITEM',
    'DAMAGE_ITEM',
    'OTHER',
]);
const cmcPackingErrorReasonSchema = z.enum([
    'CMC_GENESYS_TOTE_EXITED_IN_ERROR',
    'CMC_GENESYS_PARCEL_MEASURED_FAILED',
    'CMC_GENESYS_PARCEL_REMOVED_BEFORE_EXIT',
    'CMC_GENESYS_PARCEL_REMOVED_BEFORE_EXIT',
    'CMC_GENESYS_PARCEL_REJECTED_AT_LABELLER',
    'CMC_GENESYS_PARCEL_LABEL_NOT_FOUND',
    'CMC_GENESYS_BAD_PARCEL_PRODUCED',
    'CMC_GENESYS_INELIGIBLE_ORDER_DETECTED',
]);
const cmcGenesysTroubleshootReasonSchema = z.union([
    z.literal('CMC_GENESYS_FAILED_TO_CREATE_BOX'),
    z.literal('CMC_GENESYS_FAILED_TO_PRINT_LABEL'),
    z.literal('CMC_GENESYS_ITEM_NOT_SUITABLE'),
    z.literal('CMC_GENESYS_WRONG_BARCODE'),
    z.literal('CMC_GENESYS_OTHER'),
]);
exports.packingErrorReasonSchema = z.union([
    manualPackingCancelReasonSchema,
    cmcPackingErrorReasonSchema,
    cmcGenesysTroubleshootReasonSchema,
]);
exports.fulfilmentOrderSchema = exports.createFulfilmentOrderSchema.extend({
    status: exports.fulfilmentOrderStatusSchema,
    rejectedReason: z.optional(exports.FulfilmentOrderRejectionReason),
    parcels: z
        .array(z.object({
        stationId: z.number().optional(),
        parcelSize: packAndDispatch_1.parcelSizeSchema.optional(),
        size: z.object({
            widthMm: z.number(),
            lengthMm: z.number(),
            heightMm: z.number(),
        }),
        weightGrams: z.number().positive().optional(),
        parcelCount: packAndDispatch_1.parcelCountSchema,
    }))
        .optional(),
    labelPaths: base_1.LabelPaths.optional(),
    tags: z.array(z.string()).optional(),
    warehouseId: z.string(),
    serviceCode: z.string().optional(),
    isTest: z.boolean().optional(),
    carrierCode: z.string().optional(),
    carrier: z.string().optional(),
    trackingNumber: z.string().optional(),
    createdAt: date_1.dateSchema,
    isAutomatedPacking: z.boolean().optional(),
    packingErrorReasons: z.array(exports.packingErrorReasonSchema).optional(),
    isLabelPrinted: z.boolean().optional(),
    manifestId: z.string().optional(),
    labelRequestedAt: z.string().datetime().optional(),
    insertStatus: packAndDispatch_1.insertStatus.optional(),
    cmcErrorCode: z.string().optional(),
    forcedSendToCmcGenesys: z.boolean().optional(),
    hasItemsMissingAtPick: z.boolean().optional(),
});
/**
 * The minimum required fields in a fulfilment order to be used in a projection.
 */
exports.minimumFulfilmentOrderSchema = exports.fulfilmentOrderSchema.pick({
    id: true,
    createdAt: true,
    status: true,
    warehouseId: true,
    customer: true,
    merchantId: true,
    externalFulfilmentId: true,
    lineItems: true,
    shippingMethod: true,
});
