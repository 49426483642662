import { usePutawayPortSocket } from '../../../components/common/SocketProvider';
import { WarehouseThemeProvider } from '../../../themes/WarehouseTheme';
import { SelectReplenishmentModeView } from './SelectReplenishmentMode.view';

export const SelectReplenishmentMode = () => {
  const { dispatchCommand } = usePutawayPortSocket();

  return (
    <WarehouseThemeProvider>
      <SelectReplenishmentModeView
        onSelectReplenishmentMode={(replenishmentMode) =>
          dispatchCommand({
            type: 'SELECT_REPLENISHMENT_MODE',
            replenishmentMode,
          })
        }
      />
    </WarehouseThemeProvider>
  );
};
