"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addStockToEmptyPartitionOutcomeSchema = exports.addStockToEmptyPartitionErrorOutcomeSchema = void 0;
const zod_1 = require("zod");
exports.addStockToEmptyPartitionErrorOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('UNKNOWN_RETAIL_UNIT') }),
    zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_EMPTY') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('BIN_ALREADY_CONTAINS_RETAIL_UNIT'),
        partitionNumber: zod_1.z.number().positive(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('INVALID_QUANTITY'),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('BIN_NOT_USED_BY_MERCHANT'),
    }),
]);
exports.addStockToEmptyPartitionOutcomeSchema = zod_1.z.union([
    zod_1.z.object({ outcome: zod_1.z.literal('SUCCESS') }),
    exports.addStockToEmptyPartitionErrorOutcomeSchema,
]);
