"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignVirtualBinToMerchantOutcomeSchema = void 0;
const zod_1 = require("zod");
const generic_1 = require("./generic");
exports.assignVirtualBinToMerchantOutcomeSchema = zod_1.z.union([
    zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_NOT_FOUND') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('STORAGE_ID_ALREADY_ASSIGNED'),
        binId: zod_1.z.string().min(1),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('MERCHANT_ALREADY_HAS_VIRTUAL_BIN'),
        binId: zod_1.z.string().min(1),
    }),
    generic_1.Success,
]);
