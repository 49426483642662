import { ReactNode } from 'react';
import { ChipBase, IconWrapper } from './Chip.elements';

export type ChipProps = {
  children: ReactNode;
  icon?: ReactNode;
  variant?: ChipVariant;
};

export type ChipVariant =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'danger'
  | 'warning';

export function Chip({ children, icon, variant = 'secondary' }: ChipProps) {
  return (
    <ChipBase variant={variant}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </ChipBase>
  );
}
