import { ReactNode } from 'react';
import { ReactComponent as ArrowsSyncIcon } from '../../../assets/img/icons/arrows-sync.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/calendar.svg';
import { ReactComponent as ImportantIcon } from '../../../assets/img/icons/important-diamond.svg';
import { ReactComponent as PackageIcon } from '../../../assets/img/icons/package.svg';
import { Chip, ChipVariant } from '../Chip/Chip';

export const portCategoryChipVariants = [
  'sameday',
  'standard',
  'priority',
  'transitioning',
  'b2b',
  'manualPack',
] as const;

export type PortCategoryChipVariant = (typeof portCategoryChipVariants)[number];

export type PortCategoryChipProps = {
  children: ReactNode;
  variant?: PortCategoryChipVariant;
};

const getVariantIcon = (variant: PortCategoryChipVariant): ReactNode => {
  switch (variant) {
    case 'sameday':
      return <CalendarIcon />;
    case 'transitioning':
      return <ArrowsSyncIcon />;
    case 'priority':
      return <ImportantIcon />;
    case 'b2b':
      return <PackageIcon />;
    case 'standard':
    case 'manualPack':
    default:
      return null;
  }
};

const portCategoryChipVariantMap: Record<PortCategoryChipVariant, ChipVariant> =
  {
    sameday: 'primary',
    transitioning: 'info',
    standard: 'secondary',
    b2b: 'warning',
    priority: 'warning',
    manualPack: 'warning',
  };

export function PortCategoryChip({
  children,
  variant = 'standard',
}: PortCategoryChipProps) {
  const icon = getVariantIcon(variant);
  const chipVariant = portCategoryChipVariantMap[variant];
  return (
    <Chip variant={chipVariant} icon={icon}>
      {children}
    </Chip>
  );
}
