import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { Text } from '../Text';

export interface TileButtonProps {
  text: string;
  svg: React.ReactChild;
  onClick: () => void;
}

export const TileButton = ({ onClick, text, svg }: TileButtonProps) => {
  return (
    <StyledButton onClick={onClick} aria-label={text}>
      <ButtonContents>
        {svg}
        <Text variant="body1" tag="span">
          {text}
        </Text>
      </ButtonContents>
    </StyledButton>
  );
};

const ButtonContents = styled.div`
  display: grid;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  border: 1px ${({ theme }) => theme.palette.grey[1]} solid;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;
