import styled from 'styled-components';
import { ChipVariant } from './Chip';

interface ChipProps {
  variant: ChipVariant;
}

export const IconWrapper = styled.span`
  display: grid;
`;

export const ChipBase = styled.div<ChipProps>`
  display: inline-flex;
  align-items: center;
  padding: 2px ${({ theme }) => theme.spacing(1.25)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  font-size: 1.5rem;
  line-height: 1.5;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.palette.primary.muted};
        `;
      case 'info':
        return `
          background-color: ${theme.palette.info.base};
        `;
      case 'danger':
        return `
          background-color: ${theme.palette.danger.mid};
        `;
      case 'warning':
        return `
          background-color: ${theme.palette.warning.muted};
        `;
      case 'secondary':
      default:
        return `
          background-color: ${theme.palette.secondary.light};
        `;
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case 'danger':
        return `&> ${IconWrapper} > svg {
          fill: ${theme.palette.danger.main}
        }`;
      default:
        return null;
    }
  }}
`;
