import styled, { css } from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const ListItem = styled(ButtonBase)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: none;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    background: ${theme.palette.grey[3]};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.body1.fontSize};
    font-weight: ${theme.weight.regular};
    line-height: ${theme.typography.body1.lineHeight};

    border: none;
    border-radius: ${theme.shape.borderRadius}px;
  `};

  padding: 7px 30px;

  &:hover {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      overflow: hidden;
      z-index: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &:active {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      overflow: hidden;
      z-index: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;
