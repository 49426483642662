import { ParcelSize } from 'api-schema/lib';
import { InsertStatus } from 'api-schema/lib/model';
import PackingCartonImage from '../../../../assets//img/packing-carton.svg';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Steps } from '../../../../components/warehouse/Steps';
import { Text } from '../../../../components/warehouse/Text';
import { Thumbnail } from '../../../../components/warehouse/Thumbnail';
import { warehouseColours } from '../../../../constants/colours';
import { ParcelType } from '../../../../constants/parcel';
import { isNever } from '../../../../utils/isNever';
import { pluralise } from '../../../../utils/pluralise';
import { RetailUnitItem } from '../../PackAndDispatch.model';
import {
  AddInsertButton,
  AddInsertButtonWrapper,
  AddInsertsWrapper,
  AlertWrapper,
  CheckIcon,
  CrossIcon,
  OrderOverviewWrapper,
  ParcelInfoWrapper,
  ParcelWrapper,
  StepContentWrapper,
  ThumbnailWrapper,
} from './PackingInstruction.elements';

export type PackingInstructionProps = {
  items: RetailUnitItem[];
  parcelSize: ParcelSize;
  parcelCount: number;
  parcelType: ParcelType;
  packingNote?: string;
  isInsertRequired?: boolean;
  changeParcelTypeHandler: () => Promise<void>;
  addInsertHandler: () => Promise<void>;
  skipInsertHandler: () => Promise<void>;
  insertStatus?: InsertStatus;
};

const getParcelTypeString = (parcelType: ParcelSize) => {
  switch (parcelType) {
    case 'EXTRA_SMALL':
      return 'Extra small';
    case 'SMALL':
      return 'Small';
    case 'MEDIUM':
      return 'Medium';
    case 'LARGE':
      return 'Large';
    default:
      isNever(parcelType);
  }
};

export function PackingInstruction({
  items,
  parcelSize,
  parcelCount,
  parcelType,
  changeParcelTypeHandler,
  packingNote,
  isInsertRequired,
  insertStatus,
  skipInsertHandler,
  addInsertHandler,
}: PackingInstructionProps) {
  const itemCount = items.reduce(
    (count, item) => count + (item.quantity || 0),
    0
  );

  const stepContents = [
    {
      content: (
        <StepContentWrapper>
          <Text variant="body2">
            Place the {pluralise('item', itemCount, true)} into the parcel
          </Text>
          <OrderOverviewWrapper>
            <ThumbnailWrapper>
              {items.map(({ imageSrc, quantity, id }) => (
                <Thumbnail
                  imgSrc={imageSrc || ''}
                  counter={quantity}
                  key={id}
                />
              ))}
            </ThumbnailWrapper>
            <ParcelWrapper>
              <ParcelInfoWrapper>
                <img src={PackingCartonImage} alt="packing carton"></img>
                <Text variant="body2" tag="span" testId="parcel-type-text">
                  {getParcelTypeString(parcelSize)}{' '}
                  {parcelType.toLocaleLowerCase()} x {parcelCount || 1}
                </Text>
              </ParcelInfoWrapper>
              <Button
                variant="tertiary"
                onClick={changeParcelTypeHandler}
                testId="change-parcel-button"
              >
                Change parcel
              </Button>
            </ParcelWrapper>
          </OrderOverviewWrapper>
        </StepContentWrapper>
      ),
    },
  ];

  if (packingNote || isInsertRequired) {
    stepContents.push({
      content: (
        <StepContentWrapper>
          <AddInsertsWrapper>
            <Text variant="body2">Add inserts</Text>
            {isInsertRequired && (
              <AddInsertButtonWrapper>
                <Button
                  variant={
                    insertStatus === 'SKIPPED' ? 'dangerLight' : 'tertiary'
                  }
                  testId="no-insert-added-button"
                  icon={
                    <CrossIcon
                      fill={
                        insertStatus === 'SKIPPED'
                          ? warehouseColours.error.base
                          : warehouseColours.grey[6]
                      }
                    />
                  }
                  onClick={skipInsertHandler}
                >
                  No Insert
                </Button>
                <AddInsertButton
                  variant={
                    insertStatus === 'INSERTED' ? 'primaryLight' : 'tertiary'
                  }
                  testId="insert-added-button"
                  icon={
                    <CheckIcon
                      fill={
                        insertStatus === 'INSERTED'
                          ? warehouseColours.success.main
                          : warehouseColours.grey[6]
                      }
                    />
                  }
                  onClick={addInsertHandler}
                >
                  {insertStatus === 'INSERTED' ? 'Added Insert' : 'Add Insert'}
                </AddInsertButton>
              </AddInsertButtonWrapper>
            )}
          </AddInsertsWrapper>
          <AlertWrapper data-testId="packing-note-instruction">
            <AlertMessage type={AlertMessageTypes.Warning}>
              {packingNote}
            </AlertMessage>
          </AlertWrapper>
        </StepContentWrapper>
      ),
    });
  }
  stepContents.push(
    ...[
      {
        content: (
          <Text variant="body2">Fill the parcel with protective materials</Text>
        ),
      },
      {
        content: <Text variant="body2">Close and seal the parcel</Text>,
      },
    ]
  );

  return (
    <Container title="Pack" contentGap={false} padding="none">
      <Steps stepContents={stepContents} />
    </Container>
  );
}
