"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToteOrderResponseBody = exports.EditWarehouseRequestBody = exports.PortStateResponseBody = exports.portBayState = exports.HydratedPortsResponseBody = exports.PortsResponseBody = exports.AddTotesRequest = exports.AutostoreIntegrationResponse = exports.AutostoreIntegrationRequest = exports.WarehousesResponseBody = exports.StorageUnitCreationResult = exports.AutostoreBinConfiguration = exports.ModifyStorageUnitsRequestBody = exports.ModifyStatusStorageUnitsRequestBody = exports.StorageUnitsResponseBody = exports.ErrorBody = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const error_1 = require("../autostore-client/error");
const autostore_1 = require("../model/autostore");
const autostore_port_1 = require("../model/autostore-port");
const fulfilmentOrder_1 = require("../model/fulfilmentOrder");
const storage_1 = require("../model/storage");
const warehouse_1 = require("../model/warehouse");
/**
 * @deprecated This type should not be used as we use outcomes for all error responses. e.g. { outcome: 'STORE_NOT_FOUND' }
 * Use an outcome literal instead, e.g.
 * ```z.object({
 *      outcome: z.union([
 *         z.literal('WAREHOUSE_NOT_FOUND'),z.literal('PORT_NOT_FOUND')
 *       ]),
 *     })```
 */
exports.ErrorBody = zod_http_schemas_1.z.object({ error: zod_http_schemas_1.z.string() });
exports.StorageUnitsResponseBody = zod_http_schemas_1.z.object({
    storageUnits: zod_http_schemas_1.z.array(storage_1.storageUnitSchema),
});
exports.ModifyStatusStorageUnitsRequestBody = zod_http_schemas_1.z.object({
    storageUnitIds: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()),
    status: storage_1.StorageStatus,
});
// Can support varying kinds of update requests
exports.ModifyStorageUnitsRequestBody = exports.ModifyStatusStorageUnitsRequestBody;
exports.AutostoreBinConfiguration = zod_http_schemas_1.z.object({
    binType: autostore_1.AutostoreBinType,
    partitionsPerBin: autostore_1.PartitionsPerBin,
    numBins: zod_http_schemas_1.z.number().positive().int(),
});
exports.StorageUnitCreationResult = zod_http_schemas_1.z.object({
    binType: zod_http_schemas_1.z.optional(autostore_1.AutostoreBinType),
    partitionsPerBin: zod_http_schemas_1.z.optional(autostore_1.PartitionsPerBin),
    ids: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()),
    storageType: storage_1.StorageType,
});
exports.WarehousesResponseBody = zod_http_schemas_1.z.union([
    exports.ErrorBody,
    zod_http_schemas_1.z.object({
        warehouses: zod_http_schemas_1.z.array(warehouse_1.warehouseSchema),
    }),
]);
exports.AutostoreIntegrationRequest = zod_http_schemas_1.z.object({
    networkAddress: zod_http_schemas_1.z.string().url(),
    minBinId: zod_http_schemas_1.z.number().int().positive().optional(),
});
exports.AutostoreIntegrationResponse = zod_http_schemas_1.z.union([
    exports.ErrorBody,
    zod_http_schemas_1.z.object({
        autostore: autostore_1.autostore,
        numberOfBinsFound: zod_http_schemas_1.z.number().int().positive(),
        numberOfPortsFound: zod_http_schemas_1.z.number().int().nonnegative(),
    }),
]);
exports.AddTotesRequest = zod_http_schemas_1.z.object({
    numTotes: zod_http_schemas_1.z
        .number()
        .int()
        .positive({ message: 'Number of totes is a required field' }),
});
exports.PortsResponseBody = zod_http_schemas_1.z.object({
    ports: zod_http_schemas_1.z.array(autostore_port_1.PortConfiguration),
});
exports.HydratedPortsResponseBody = zod_http_schemas_1.z.object({
    outcome: zod_http_schemas_1.z.literal('SUCCESS'),
    ports: zod_http_schemas_1.z.array(autostore_port_1.portHydratedResponseSchema),
});
exports.portBayState = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.undefined(),
    zod_http_schemas_1.z.string(),
    zod_http_schemas_1.z
        .object({
        bayId: zod_http_schemas_1.z.string(),
        toteId: zod_http_schemas_1.z.string().nullable(),
        state: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.literal('Opening'),
            zod_http_schemas_1.z.literal('Opened'),
            zod_http_schemas_1.z.literal('Closing'),
            zod_http_schemas_1.z.literal('Closed'),
        ]),
        taskType: zod_http_schemas_1.z.number(),
        locationId: zod_http_schemas_1.z.string(),
    })
        .array(),
]);
exports.PortStateResponseBody = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PORT_NOT_FOUND'),
        warehouseId: zod_http_schemas_1.z.string(),
        portId: zod_http_schemas_1.z.number(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PORT_ID_NOT_A_NUMBER'),
        portIdParam: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.union([
            error_1.getPortStatusErrorOutcome,
            zod_http_schemas_1.z.literal('UNHANDLED_AUTOSTORE_MANAGER_ERROR'),
            zod_http_schemas_1.z.literal('NO_AUTOSTORE'),
            zod_http_schemas_1.z.literal('AUTOSTORE_NOT_RESPONDING'),
        ]),
        payload: zod_http_schemas_1.z.object({
            wmsPortState: autostore_port_1.portStateSchema,
            portBayState: exports.portBayState,
            autostoreError: zod_http_schemas_1.z.string().optional(),
        }),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('SUCCESS'),
        payload: zod_http_schemas_1.z.object({
            wmsPortState: autostore_port_1.portStateSchema,
            portBayState: exports.portBayState,
            autostorePortState: autostore_port_1.autostorePortStatusSchema,
        }),
    }),
]);
exports.EditWarehouseRequestBody = zod_http_schemas_1.z.object({
    name: zod_http_schemas_1.z.string(),
    address: zod_http_schemas_1.z.string(),
    isTest: zod_http_schemas_1.z.boolean(),
    autostore: zod_http_schemas_1.z.object({
        networkAddress: zod_http_schemas_1.z.string(),
    }),
});
exports.ToteOrderResponseBody = zod_http_schemas_1.z.union([
    exports.ErrorBody,
    zod_http_schemas_1.z.object({
        fulfilmentOrder: fulfilmentOrder_1.fulfilmentOrderSchema,
    }),
]);
