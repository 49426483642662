import { InsertStatus } from 'api-schema/lib/model';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../components/warehouse/AlertMessage/AlertMessage';
import { Box } from '../../../components/warehouse/Box';
import { Button } from '../../../components/warehouse/Button';
import { Container } from '../../../components/warehouse/Container';
import { StationContainer } from '../../../components/warehouse/StationContainer';
import { Steps } from '../../../components/warehouse/Steps';
import { AlertWrapper } from '../../../components/warehouse/Steps/Steps.elements';
import { Text } from '../../../components/warehouse/Text';
import { warehouseColours } from '../../../constants/colours';
import { pluralise } from '../../../utils/pluralise';
import {
  AddInsertButton,
  AddInsertButtonWrapper,
  AddInsertsWrapper,
  CheckIcon,
  CrossIcon,
  StepContentWrapper,
} from '../../packAndDispatch/components/PackingInstruction/PackingInstruction.elements';
import { ConfirmSendToteToManualPackModal } from '../components/ConfirmSendToteToManualPackModal';
import { ToteAdjustmentConfirmModal } from '../components/ToteAjustmentConfirmModal/ToteAdjustmentConfirm';

type ToteAdjustmentProps = {
  itemCount?: number;
  fulfilmentOrderId?: string;
  merchantName?: string;
  externalOrderReference?: string;
  customerName?: string;
  isInsertRequired: boolean;
  insertStatus?: InsertStatus;
  packingNote?: string;
  shouldUseInsertButtons?: boolean;
  confirmToteAdjustmentModalVisible: boolean;
  confirmSendToteToManualPackModalVisible: boolean;
  handleToteAdjustmentConfirm: () => void;
  handleAdjustmentComplete: () => void;
  pressSendToManualPack: () => void;
  confirmSendToteToManualPack: () => void;
  cancelSendToManualPack: () => void;
};

const Video = styled.video`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: none;
`;

export function ToteAdjustmentView({
  itemCount,
  fulfilmentOrderId,
  merchantName,
  externalOrderReference,
  customerName,
  isInsertRequired,
  insertStatus,
  packingNote,
  shouldUseInsertButtons,
  handleToteAdjustmentConfirm,
  handleAdjustmentComplete,
  confirmSendToteToManualPack,
  cancelSendToManualPack,
  pressSendToManualPack,
  confirmSendToteToManualPackModalVisible = false,
  confirmToteAdjustmentModalVisible = false,
}: Readonly<ToteAdjustmentProps>): ReactElement {
  useEffect(() => {
    if (
      confirmToteAdjustmentModalVisible ||
      confirmSendToteToManualPackModalVisible
    ) {
      // This is to lock scrolling
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [
    confirmToteAdjustmentModalVisible,
    confirmSendToteToManualPackModalVisible,
  ]);

  const stepContents = [
    {
      content: (
        <Text>
          Please wrap the products in honeycomb or protective paper if required.
        </Text>
      ),
    },
  ];
  if (isInsertRequired || !!packingNote) {
    stepContents.push({
      content: (
        <StepContentWrapper>
          <AddInsertsWrapper>
            <Text variant="body2">Add inserts</Text>
            {isInsertRequired && shouldUseInsertButtons && (
              <AddInsertButtonWrapper>
                <Button
                  variant={
                    insertStatus === 'SKIPPED' ? 'dangerLight' : 'tertiary'
                  }
                  testId="no-insert-added-button"
                  icon={
                    <CrossIcon
                      fill={
                        insertStatus === 'SKIPPED'
                          ? warehouseColours.error.base
                          : warehouseColours.grey[6]
                      }
                    />
                  }
                  // TODO(FCM-386): This component is not being rendered at the moment.
                  // Will replace it with an actual function in FCM-386
                  onClick={() => console.log('skipInsertHandler')}
                >
                  No Insert
                </Button>
                <AddInsertButton
                  variant={
                    insertStatus === 'INSERTED' ? 'primaryLight' : 'tertiary'
                  }
                  testId="insert-added-button"
                  icon={
                    <CheckIcon
                      fill={
                        insertStatus === 'INSERTED'
                          ? warehouseColours.success.main
                          : warehouseColours.grey[6]
                      }
                    />
                  }
                  // TODO(FCM-386): This component is not being rendered at the moment.
                  // Will replace it with an actual function in FCM-386
                  onClick={() => console.log('addInsertHandler')}
                >
                  {insertStatus === 'INSERTED' ? 'Added Insert' : 'Add Insert'}
                </AddInsertButton>
              </AddInsertButtonWrapper>
            )}
          </AddInsertsWrapper>
          <AlertWrapper data-testId="packing-note-instruction">
            <AlertMessage type={AlertMessageTypes.Warning}>
              {packingNote}
            </AlertMessage>
          </AlertWrapper>
        </StepContentWrapper>
      ),
    });
  }
  stepContents.push(
    {
      content: (
        <Box>
          <Text>
            Adjust the tote to the size of the contents and place it on the main
            conveyor belt at the right orientation.
          </Text>
          <Video
            src="https://storage.googleapis.com/waretopia-prod-documents-bfea6a3/static/vary-tote.mp4"
            controls={true}
            loop={true}
            autoPlay={true}
          />
        </Box>
      ),
    },
    {
      content: (
        <Text>
          Please confirm that the tote is suitable for packing using the CMC
          machine. If you are unsure, please check with your supervisor.
        </Text>
      ),
    }
  );

  return (
    <StationContainer
      title={itemCount && `${pluralise('item', itemCount)} in tote`}
      subTitle={fulfilmentOrderId && `Order #${fulfilmentOrderId}`}
      sideText={
        <>
          {merchantName && externalOrderReference && (
            <Text variant="body2" weight="medium" tag="span">
              {merchantName} reference {externalOrderReference}
            </Text>
          )}
          {customerName && (
            <Text variant="body2" weight="regular" tag="span">
              <Text variant="body2" weight="medium" tag="span">
                Recipient:
              </Text>{' '}
              {customerName}
            </Text>
          )}
        </>
      }
      stickyFooter={[
        <Button
          key="intermediary-station-send-to-manual-pack-button"
          variant="warning"
          onClick={pressSendToManualPack}
        >
          Send to Manual Packing
        </Button>,
        <Button
          key="intermediary-station-tote-adjustment-complete-button"
          variant="primary"
          onClick={handleAdjustmentComplete}
        >
          Adjustment complete
        </Button>,
      ]}
    >
      <Container padding="none">
        <Steps stepContents={stepContents} />
      </Container>
      <ToteAdjustmentConfirmModal
        isVisible={confirmToteAdjustmentModalVisible}
        onConfirm={handleToteAdjustmentConfirm}
      />
      <ConfirmSendToteToManualPackModal
        isVisible={confirmSendToteToManualPackModalVisible}
        onCancel={cancelSendToManualPack}
        onConfirm={confirmSendToteToManualPack}
      />
    </StationContainer>
  );
}
