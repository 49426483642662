"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.movePartitionsToVirtualBinOutcomeSchema = void 0;
const zod_1 = require("zod");
const storage_1 = require("../../events/storage");
exports.movePartitionsToVirtualBinOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_HAS_NO_VIRTUAL_BIN') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_DO_NOT_EXIST'),
        partitionNumbers: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_HAVE_QUANTITY_ALLOCATED'),
        allocatedPartitions: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_HAVE_NO_RETAIL_UNIT'),
        partitions: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('SUCCESS'),
        autostoreBinId: zod_1.z.number(),
        toVirtualBinId: zod_1.z.string(),
        binNowEmpty: zod_1.z.boolean(),
        partitionsToMove: storage_1.partitionsMovedToVirtualBinSchema.shape.payload.shape.partitionsMoved,
    }),
]);
