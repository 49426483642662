"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.autostorePortStatusSchema = exports.portHydratedResponseSchema = exports.portStateSchema = exports.PortConfiguration = exports.hydratedPortSchema = exports.portSchema = exports.putawayPortWithCurrentBinSchema = exports.putawayPortSchema = exports.putawayPortStatusSchema = exports.pickPortWithHydratedEntitiesSchema = exports.toteLocationWithPicklistRequiredSchema = exports.toteLocationWithHydratedPicklistSchema = exports.PickPortStateModel = exports.pickForCurrentPickCycle = exports.PickPortPushedLocations = exports.PickPortPreviousPick = exports.PickPortCurrentPick = exports.LockReason = exports.AutostorePortStatus = exports.PickPortStatus = exports.unconfiguredPortWithCurrentBinSchema = exports.UnconfiguredPortStateModel = exports.portTypeSchema = exports.toteLocationSchema = exports.binStateSchema = exports.pickingStateSchema = void 0;
const z = __importStar(require("zod"));
const featureFlags_1 = require("./featureFlags");
const merchant_1 = require("./merchant");
const pickingMode_1 = require("./pickingMode");
const picklist_1 = require("./picklist");
const retailUnit_1 = require("./retailUnit");
const storage_1 = require("./storage");
const transfer_1 = require("./transfer");
const warehouse_1 = require("./warehouse");
const PortBinState = z.object({
    id: z.string().min(1),
    autostoreBinId: z.number(),
    currentPortId: z.number().optional(),
    partitions: z.array(storage_1.partitionWithRetailUnitSchema),
});
/**
 * The picking and bin states below (in combination with existing tote location states) are new states designed to replace a lot of the overall portStatus.
 * They should allow us to perform more processes in parallel such as getting a new bin and determining the next pick while getting and linking new totes.
 *
 * This is a minimal initial implmentation that is expected to grow.
 * Here you can find a state chart showing this parallel state model:
 * https://stately.ai/registry/editor/a4f040f5-3e9b-4529-b636-b32f82a4c810?machineId=88ef4c02-d2c2-4a59-8e9d-7122e09d2307&mode=design
 */
exports.pickingStateSchema = z.enum([
    'IDLE',
    'PICKING',
    'PICKING_COMPLETE',
]);
exports.binStateSchema = z.enum(['CLOSED', 'AWAITING_BIN', 'OPEN']);
exports.toteLocationSchema = z.object({
    locationId: z.string().min(1),
    toteId: z.string().nullable(),
    state: z.enum([
        'NO_TOTE',
        'UNLINKED',
        'SCANNED',
        'LINKED',
        'READY',
        'ASSIGNED',
        'REQUIRES_RED_PAPER',
        'REQUIRES_GREEN_PAPER',
        'COMPLETE',
        'REQUIRES_MISSING_ITEM_PAPER',
    ]),
    picklistId: z.string().optional(),
    pickCount: z.number().optional(),
});
const PortTypeUnconfigured = z.literal('UNCONFIGURED');
const PortTypePick = z.literal('PICK');
const PortTypePutaway = z.literal('PUTAWAY');
exports.portTypeSchema = z.enum(['UNCONFIGURED', 'PICK', 'PUTAWAY']);
const unconfiguredPortStatus = z.enum([
    'OPEN',
    'CLOSED', // Port is closed
]);
exports.UnconfiguredPortStateModel = z.object({
    warehouseId: z.string(),
    portId: z.number(),
    status: unconfiguredPortStatus,
    type: PortTypeUnconfigured,
    currentBinId: z.string().optional(),
    currentAutostoreBinId: z.number().optional(),
    releaseChannel: featureFlags_1.releaseChannel.optional(),
});
exports.unconfiguredPortWithCurrentBinSchema = exports.UnconfiguredPortStateModel.extend({
    currentBin: storage_1.autostoreBinWithRetailUnitsSchema.optional(),
});
exports.PickPortStatus = z.enum([
    'OPENING',
    'OPEN',
    'CLOSING',
    'PLACING_TOTES',
    'LINKING',
    'PICK_COMPLETE',
    'PICK_CONFIRM_MISSING_ITEM',
    'PUSHING',
    'REPLACING_TOTES',
    'CLOSED',
    'AWAITING_TASK_GROUP_ASSIGNMENT',
    'AWAITING_BIN',
    'NO_TASK_GROUP',
    'READY',
    'LOCATIONS_CLOSED',
    'UNEXPECTED_BIN',
    'PLACING_RED_PAPER',
    'PLACING_GREEN_PAPER',
    'ERROR_CLOSING_BIN',
    'READY_TO_RETRY_PICKING',
    'PLACING_MISSING_ITEM_PAPER',
    'CONFIRMING_MISSING_ITEM_DETECTION',
    'ZERO_COUNT_CROSS_CHECK',
    'ZERO_COUNT_CROSS_CHECK_ERROR', // Show modal in the case of error state in zero count cross check flow
]);
exports.AutostorePortStatus = z.enum([
    'OPEN',
    'CLOSED', // Port is closed
]);
exports.LockReason = z.enum([
    'MANUAL_LOCK',
    'TASK_GROUP_ASSIGNED_WHEN_PICKLISTS_PRESENT',
    'TASK_GROUP_WAS_CANCELLED',
    'TASK_GROUP_ASSIGNED_WITH_INSUFFICIENT_STOCK',
    'TASK_GROUP_ASSIGNED_WITH_CANCELLED_PICKLISTS',
    'TASK_GROUP_ASSIGNED_WITH_INVALID_FULFILMENT_ORDERS',
    'ABNORMAL_PICK_QUANTITY',
    'PICK_COUNT_MISMATCH',
    'PICKLIST_NOT_ASSIGNED_TO_TOTE',
    'OVER_PICKED_PICKLIST_LINE_ITEM',
    'CONVEYOR_TOTES_NOT_MATCHING_TOTE_LOCATIONS',
    'TASK_GROUP_MISSING_BINS',
    'MISSING_TASK_ID',
    'TOTE_ALREADY_LINKED_AT_ANOTHER_TOTE_LOCATION', // Tote id which has already been linked to a tote location (possibly at another port) was attempted to be linked again
]);
exports.PickPortCurrentPick = z.object({
    item: retailUnit_1.retailUnitSchema,
    quantity: z.number().int().positive(),
    partitionNumber: z.number().int().positive(),
    isShortPick: z.boolean(),
});
exports.PickPortPreviousPick = z.object({
    pick: exports.PickPortCurrentPick,
    quantityPickedPerLocation: z.array(z.number().nonnegative().int()),
});
exports.PickPortPushedLocations = z.object({
    locationIndex: z.number().nonnegative().int(),
    locationData: exports.toteLocationSchema,
});
const completedPickSchema = z.object({
    state: z.literal('PICKED'),
    locationId: exports.toteLocationSchema.shape.locationId,
    picklistId: z.string(),
    toteId: z.string(),
    quantityPicked: z.number().positive(),
    quantityToPick: z.number().positive(),
    fulfilmentOrderId: z.string(),
    isToteComplete: z.boolean().optional(),
});
const plannedPickSchema = z.object({
    state: z.literal('PLANNED'),
    locationId: exports.toteLocationSchema.shape.locationId,
    picklistId: z.string(),
    fulfilmentOrderId: z.string().optional(),
    toteId: z.string().optional(),
    quantityPicked: z.number(),
    quantityToPick: z.number().positive(),
});
exports.pickForCurrentPickCycle = z.discriminatedUnion('state', [
    completedPickSchema,
    plannedPickSchema,
]);
const pickItemBarcodeValidationFailedReasonSchema = z.discriminatedUnion('outcome', [
    z.object({
        outcome: z.literal('INVALID_PORT_STATUS'),
        expectedPortStatus: z.literal('OPEN'),
        currentPortStatus: z.string(),
    }),
    z.object({
        outcome: z.literal('INVALID_PORT_TYPE'),
        expectedType: z.literal('PICK'),
        actualType: z.string(),
    }),
    z.object({
        outcome: z.literal('PORT_NOT_FOUND'),
    }),
    z.object({
        outcome: z.literal('INVALID_PICK_ITEM_BARCODE'),
        expectedBarcode: z.string(),
        scannedBarcode: z.string(),
    }),
    z.object({
        outcome: z.literal('NO_CURRENT_PICK_ASSIGNED_TO_PORT'),
    }),
]);
const itemBarcodeValidationSchema = z.union([
    z.object({
        isValid: z.literal(true),
        scannedBarcode: z.string(),
    }),
    z.object({
        isValid: z.literal(false),
        scannedBarcode: z.string().optional(),
        reason: pickItemBarcodeValidationFailedReasonSchema,
    }),
]);
const autostorePortBaseSchema = z.object({
    warehouseId: z.string(),
    portId: z.number(),
    autostorePortStatus: exports.AutostorePortStatus,
    currentBinId: z.string().optional(),
    currentAutostoreBinId: z.number().optional(),
    releaseChannel: featureFlags_1.releaseChannel.optional(),
    hasAutostorePortError: z.boolean(),
});
exports.PickPortStateModel = autostorePortBaseSchema.extend({
    status: exports.PickPortStatus,
    pickingState: exports.pickingStateSchema,
    binState: exports.binStateSchema,
    type: PortTypePick,
    toteLocationCount: z.number().positive().optional(),
    toteLocations: z.array(exports.toteLocationSchema),
    currentTaskGroupId: z.string().optional(),
    currentAutostoreTaskId: z.number().int().nonnegative().optional(),
    currentPick: exports.PickPortCurrentPick.optional(),
    previousPick: exports.PickPortPreviousPick.optional(),
    pushedLocations: z.array(exports.PickPortPushedLocations).optional(),
    isClosing: z.boolean(),
    isLocked: z.boolean(),
    lockReason: exports.LockReason.optional(),
    currentWarehouse: warehouse_1.warehouseSchema.optional(),
    categories: pickingMode_1.autostoreCategorySchema.array(),
    pickingModes: pickingMode_1.pickingModeSchema.array().optional(),
    queuedSelectedCategories: pickingMode_1.autostoreCategorySchema.array().nullable(),
    queuedPickingModes: pickingMode_1.pickingModeNameSchema.array().optional(),
    nextAutostoreTaskId: z.number().optional(),
    shouldUseConveyorSystem: z.boolean(),
    picksForCurrentPickCycle: z.array(exports.pickForCurrentPickCycle).optional(),
    itemBarcodeValidation: itemBarcodeValidationSchema.optional(),
});
exports.toteLocationWithHydratedPicklistSchema = exports.toteLocationSchema.merge(z.object({
    picklist: picklist_1.picklistSchema.optional(),
}));
exports.toteLocationWithPicklistRequiredSchema = exports.toteLocationSchema.merge(z.object({
    picklist: picklist_1.picklistSchema,
    picklistId: z.string(),
}));
exports.pickPortWithHydratedEntitiesSchema = exports.PickPortStateModel.extend({
    toteLocations: z.array(exports.toteLocationWithHydratedPicklistSchema),
    currentBin: storage_1.autostoreBinWithRetailUnitsSchema.optional(),
});
exports.putawayPortStatusSchema = z.enum([
    'OPEN',
    'CLOSED',
    'AWAITING_BIN',
    'READY', // Port "open" but no bin requested
]);
exports.putawayPortSchema = autostorePortBaseSchema.extend({
    status: exports.putawayPortStatusSchema,
    type: PortTypePutaway,
    replenishmentMode: z
        .enum(['STOCK_TRANSFER', 'RETURNS_RESTOCKING'])
        .optional(),
    currentTransfer: transfer_1.transferSchema.optional(),
    currentTransferId: z.string().optional(),
    currentWarehouse: warehouse_1.warehouseSchema.optional(),
    putawayMode: merchant_1.putawayModeSchema.optional(),
});
exports.putawayPortWithCurrentBinSchema = exports.putawayPortSchema.extend({
    currentBin: storage_1.autostoreBinWithRetailUnitsSchema.optional(),
});
exports.portSchema = z.discriminatedUnion('type', [
    exports.UnconfiguredPortStateModel,
    exports.putawayPortSchema,
    exports.PickPortStateModel,
]);
exports.hydratedPortSchema = z.discriminatedUnion('type', [
    exports.pickPortWithHydratedEntitiesSchema,
    exports.putawayPortWithCurrentBinSchema,
    exports.UnconfiguredPortStateModel,
]);
exports.PortConfiguration = z.object({
    portId: z.number().nonnegative(),
    type: exports.portTypeSchema,
    currentAutostoreBinId: z.number().optional(),
    currentBinId: z.string().optional(),
});
exports.portStateSchema = exports.PortConfiguration.extend({
    status: z.enum([
        ...exports.PickPortStatus.options,
        ...exports.putawayPortStatusSchema.options,
        ...unconfiguredPortStatus.options,
    ]),
    autostorePortStatus: exports.AutostorePortStatus.optional(),
    currentBinId: z.string().optional(),
    categories: pickingMode_1.autostoreCategorySchema.array().optional(),
    pickingModes: pickingMode_1.pickingModeSchema.array().optional(),
    queuedSelectedCategories: pickingMode_1.autostoreCategorySchema
        .array()
        .nullable()
        .optional(),
    queuedPickingModes: pickingMode_1.pickingModeNameSchema.array().optional(),
    currentTaskGroupId: z.string().optional(),
    shouldUseConveyorSystem: z.boolean().optional(),
    isLocked: z.boolean().optional(),
    lockReason: exports.LockReason.optional(),
    hasAutostorePortError: z.boolean().optional(),
});
exports.portHydratedResponseSchema = exports.PortConfiguration.extend({
    warehouseId: z.string(),
    status: z.enum([
        ...exports.PickPortStatus.options,
        ...exports.putawayPortStatusSchema.options,
        ...unconfiguredPortStatus.options,
    ]),
    type: exports.portTypeSchema,
    releaseChannel: featureFlags_1.releaseChannel.optional(),
    toteLocations: z.array(exports.toteLocationWithHydratedPicklistSchema).optional(),
    currentBinId: z.string().optional(),
    currentBin: storage_1.autostoreBinWithRetailUnitsSchema.optional(),
    isClosing: z.boolean().optional(),
    isLocked: z.boolean().optional(),
    lockReason: exports.LockReason.optional(),
    categories: pickingMode_1.autostoreCategorySchema.array().optional(),
    pickingModes: pickingMode_1.pickingModeSchema.array().optional(),
    queuedSelectedCategories: pickingMode_1.autostoreCategorySchema
        .array()
        .nullable()
        .optional(),
    previousPick: exports.PickPortPreviousPick.optional(),
    pushedLocations: z.array(exports.PickPortPushedLocations).optional(),
    currentTaskGroupId: z.string().optional(),
    shouldUseConveyorSystem: z.boolean().optional(),
    picksForCurrentPickCycle: z.array(exports.pickForCurrentPickCycle).optional(),
    currentPick: exports.PickPortCurrentPick.optional(),
    hasAutostorePortError: z.boolean().optional(),
});
exports.autostorePortStatusSchema = z.object({
    portId: z.number().positive(),
    mode: z.union([z.literal('OPEN'), z.literal('CLOSED')]),
    selectedBin: z.number().optional(),
    selectedTask: z.number().optional(),
    isReady: z.boolean(),
});
