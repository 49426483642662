"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INTERMEDIARY_STATION_SOCKET_EVENT_NAMES = exports.intermediaryStationEventSchema = exports.intermediaryStationSocketEventSchema = exports.releaseChannelResetOnIntermediaryStationSchema = exports.releaseChannelAssignedToIntermediaryStationSchema = exports.intermediaryStationToteSentToManualPackFailedSchema = exports.intermediaryStationToteSentToManualPackSchema = exports.intermediaryStationToteAdjustmentConfirmationFailureSchema = exports.intermediaryStationToteAdjustmentConfirmedSchema = exports.intermediaryStationToteAdjustmentFailureEventSchema = exports.intermediaryStationToteAdjustedSchema = exports.intermediaryStationToteRemovalFailedSchema = exports.intermediaryStationToteRemovedSchema = exports.intermediaryStationConfigurationFailedSchema = exports.intermediaryStationConfiguredSchema = exports.intermediaryStationAddFailedSchema = exports.intermediaryStationAddedSchema = exports.intermediaryStationToteArrivalFailedSchema = exports.intermediaryStationToteArrivedSchema = exports.intermediaryStationCloseFailedSchema = exports.intermediaryStationClosedSchema = exports.intermediaryStationOpenFailedSchema = exports.intermediaryStationOpenedSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.intermediaryStationOpenedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_OPENED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
    }),
});
exports.intermediaryStationOpenFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_OPEN_INTERMEDIARY_STATION'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_CLOSED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
    }),
});
exports.intermediaryStationCloseFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_INTERMEDIARY_STATION_FAILED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        reason: zod_1.z.enum([
            'INTERMEDIARY_STATION_NOT_FOUND',
            'INTERMEDIARY_STATION_ALREADY_CLOSED',
        ]),
    }),
});
exports.intermediaryStationToteArrivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_ARRIVED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
        fulfilmentOrderId: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteArrivalFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_HANDLE_INTERMEDIARY_TOTE_ARRIVAL'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number().optional(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string().optional(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_ADDED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationAddFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_ADD_INTERMEDIARY_STATION'),
    payload: zod_1.z.object({
        conveyorBayId: zod_1.z.string(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationConfiguredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_CONFIGURED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationConfigurationFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_CONFIGURE_INTERMEDIARY_STATION'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        conveyorBayId: zod_1.z.string(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationToteRemovedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_REMOVED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteRemovalFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_REMOVAL_FAILED'),
    payload: zod_1.z.object({
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
        stationId: zod_1.z.number().optional(),
        reason: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteAdjustedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_ADJUSTED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteAdjustmentFailureEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_ADJUST_INTERMEDIARY_STATION_TOTE'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationToteAdjustmentConfirmedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_ADJUSTMENT_CONFIRMED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteAdjustmentConfirmationFailureSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_CONFIRM_INTERMEDIARY_STATION_TOTE_ADJUSTMENT'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.intermediaryStationToteSentToManualPackSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INTERMEDIARY_STATION_TOTE_SENT_TO_MANUAL_PACK'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        conveyorBayId: zod_1.z.string(),
    }),
});
exports.intermediaryStationToteSentToManualPackFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_SEND_INTERMEDIARY_TOTE_TO_MANUAL_PACK'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        toteId: zod_1.z.string(),
        reason: zod_1.z.string().optional(),
    }),
});
exports.releaseChannelAssignedToIntermediaryStationSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_ASSIGNED_TO_INTERMEDIARY_STATION'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
        releaseChannel: zod_1.z.union([
            zod_1.z.literal('ALPHA'),
            zod_1.z.literal('BETA'),
            zod_1.z.literal('STABLE'),
        ]),
    }),
});
exports.releaseChannelResetOnIntermediaryStationSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_RESET_ON_INTERMEDIARY_STATION'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number(),
    }),
});
exports.intermediaryStationSocketEventSchema = zod_1.z.union([
    exports.intermediaryStationOpenedSchema,
    exports.intermediaryStationOpenFailedSchema,
    exports.intermediaryStationClosedSchema,
    exports.intermediaryStationToteArrivedSchema,
    exports.intermediaryStationAddedSchema,
    exports.intermediaryStationConfiguredSchema,
    exports.intermediaryStationConfigurationFailedSchema,
    exports.intermediaryStationToteRemovedSchema,
    exports.intermediaryStationToteAdjustedSchema,
    exports.intermediaryStationToteAdjustmentFailureEventSchema,
    exports.intermediaryStationToteAdjustmentConfirmedSchema,
    exports.intermediaryStationToteAdjustmentConfirmationFailureSchema,
    exports.intermediaryStationToteSentToManualPackSchema,
    exports.intermediaryStationToteSentToManualPackFailedSchema,
    exports.releaseChannelAssignedToIntermediaryStationSchema,
    exports.releaseChannelResetOnIntermediaryStationSchema,
]);
exports.intermediaryStationEventSchema = zod_1.z.union([
    ...exports.intermediaryStationSocketEventSchema.options,
    exports.intermediaryStationToteArrivalFailedSchema,
    exports.intermediaryStationAddFailedSchema,
    exports.intermediaryStationToteRemovalFailedSchema,
    exports.intermediaryStationCloseFailedSchema,
]);
exports.INTERMEDIARY_STATION_SOCKET_EVENT_NAMES = exports.intermediaryStationSocketEventSchema.options.map((opt) => opt.shape.type.value);
