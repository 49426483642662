import styled from 'styled-components';

export const ClosingText = styled.span`
  color: ${({ theme }) => theme.palette.danger.main};
`;

export const HeaderActionWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const HeaderInformation = styled.div`
  display: inline-flex;
  align-items: left;
  gap: ${({ theme }) => theme.spacing(2)};
`;
