import { AutostorePortBase } from 'api-schema/lib/model';
import { ReactComponent as AlertCircle } from '../../../../assets/img/icons/alert-circle.svg';
import { Chip } from '../../../../components/warehouse/Chip';
import { Text } from '../../../../components/warehouse/Text';

export function AutostorePortError({
  hasAutostorePortError,
}: Partial<AutostorePortBase>) {
  if (!hasAutostorePortError) {
    return null;
  }
  const icon = <AlertCircle />;
  return (
    <Chip variant={'danger'} icon={icon}>
      <Text tag="span">Autostore Port Error</Text>
    </Chip>
  );
}
