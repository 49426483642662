import {
  PackAndDispatchClientState,
  ParcelSize,
  Picklist,
} from 'api-schema/lib';
import { ManualPackingCancelReason } from 'api-schema/lib/model';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { ReactElement } from 'react';
import { AlertMessageTypes } from '../../components/warehouse/AlertMessage';
import { Layout } from '../../components/warehouse/Layout';
import { StartStation } from '../../components/warehouse/StartStation';
import { StationHeader } from '../../components/warehouse/StationHeader';
import { Text } from '../../components/warehouse/Text';
import { ToteScan } from '../../components/warehouse/ToteScan';
import { getParcelTypeFromOrderTags } from '../../constants/parcel';
import { useCurrentWarehouse } from '../../hooks/useCurrentWarehouse';
import { isNever } from '../../utils/isNever';
import { CannotPackOrderModal } from './components/CannotPackOrderModal/CannotPackOrderModal';
import { CheckItems } from './components/CheckItems';
import { ForceFinishPackingTroubleshootModal } from './components/ForceFinishPackingTroubleshootModal/ForceFinishPackingTroubleshootModal';
import { LabelPrinting } from './components/LabelPrinting';
import { PackAndPrint } from './components/PackAndPrint';
import { PackAndPrintErrorType } from './components/PackAndPrint/PackAndPrint.elements';
import { PackingTroubleshootLabelPrintingModal } from './components/PackingTroubleshootLabelPrintingModal/PackingTroubleshootModalPrintingModal';
import { SelectParcel } from './components/SelectParcel';
import { TroubleshootLabelPrinting } from './components/TroubleshootLabelPrinting';
import { StatusType } from './PackAndDispatch.model';

type PackAndDispatchStationProps = {
  stationId: string;
  packingState: PackAndDispatchClientState;
  printStatus: StatusType;
  scanToteErrorMessage?: string;
  featureFlags: FeatureFlags;
  isTroubleshootPrintingViaNetwork: boolean;
  isTroubleshootModalOpen: boolean;
  isPrintingPackingTroubleshootLabel: boolean;
  isForceFinishPackingTroubleshootModalOpen: boolean;
  closeStation: () => void;
  startPackingHandler: () => Promise<void>;
  scanBarcodeAtPackingStationHandler: (toteId: string) => Promise<void>;
  checkPackingToteHandler: () => Promise<void>;
  setIsTroubleshootPrintingViaNetwork: (value: boolean) => void;
  handleSelectParcel: ({
    parcelSize,
    parcelCount,
  }: {
    parcelSize: ParcelSize;
    parcelCount: number;
  }) => void;
  handleStartLabelPrinting: () => Promise<void>;
  handlePrintLabelViaNetwork: () => Promise<void>;
  handlePrintTroubleshootLabel: () => Promise<void>;
  handleChangeParcelType: () => Promise<void>;
  handleDisposeLabel: () => Promise<void>;
  handleCloseDisposeOldLabelModal: () => void;
  isDisposeOldLabelModalOpen: boolean;
  addInsert: () => Promise<void>;
  skipInsert: () => Promise<void>;
  handlePackOrder: () => Promise<void>;
  hideULDModal: () => void;
  handleSortParcel: () => Promise<void>;
  handleCompleteLabelPrinting: () => Promise<void>;
  handleGenerateTroubleshootLabel: () => Promise<void>;
  handlePrintTroubleshootLabelViaNetwork: () => Promise<void>;
  handleTransferParcelToTroubleshoot: () => Promise<void>;
  handleRevertTroubleshootLabel: () => Promise<void>;
  packAndPrintError: PackAndPrintErrorType;
  onPackingCancel: (reasons: ManualPackingCancelReason[]) => Promise<void>;
  onTroubleshootModalOpen: () => void;
  onTroubleshootModalClose: () => void;
  onForceFinishPackingTroubleshoot: () => void;
};

const getLabelStatus = (
  packingState: PackAndDispatchClientState
): StatusType => {
  if (packingState.labelPaths && packingState.labelPaths.zpl) {
    return 'SUCCESS';
  } else if (packingState.hasLabelError) {
    return 'ERROR';
  }
  return 'INITIAL';
};

export function PackAndDispatchStationView(
  props: PackAndDispatchStationProps
): ReactElement {
  const {
    packingState,
    printStatus,
    scanToteErrorMessage,
    startPackingHandler,
    scanBarcodeAtPackingStationHandler,
    checkPackingToteHandler,
    stationId,
    handleSelectParcel,
    isTroubleshootPrintingViaNetwork,
    setIsTroubleshootPrintingViaNetwork,
    handleStartLabelPrinting,
    handlePrintLabelViaNetwork,
    handlePrintTroubleshootLabel,
    handleChangeParcelType,
    isDisposeOldLabelModalOpen,
    handleDisposeLabel,
    handleCloseDisposeOldLabelModal,
    hideULDModal,
    handlePackOrder,
    handleSortParcel,
    handleCompleteLabelPrinting,
    closeStation,
    handleGenerateTroubleshootLabel,
    handlePrintTroubleshootLabelViaNetwork,
    handleTransferParcelToTroubleshoot,
    handleRevertTroubleshootLabel,
    addInsert,
    skipInsert,
    packAndPrintError,
    isTroubleshootModalOpen,
    onPackingCancel,
    onTroubleshootModalOpen,
    onTroubleshootModalClose,
    isPrintingPackingTroubleshootLabel,
    isForceFinishPackingTroubleshootModalOpen,
    onForceFinishPackingTroubleshoot,
  } = props;
  const currentWarehouse = useCurrentWarehouse();

  const getItemsFromPicklist = (picklist: Picklist) =>
    picklist.lineItems
      .filter(
        (lineItem) => lineItem.quantityToPick - lineItem.quantityMissing > 0
      )
      .map((lineItem) => ({
        ...lineItem,
        id: lineItem.retailUnitId ?? '',
        merchantId: lineItem.merchantId ?? '',
        weightGrams: lineItem.weightGrams ?? 0,
        quantity: lineItem.quantityToPick - lineItem.quantityMissing,
      }));

  const getScreenFromStatus = (
    status: PackAndDispatchClientState['status']
  ) => {
    switch (status) {
      case 'OPEN':
        return (
          <ToteScan
            alert={{
              message: scanToteErrorMessage,
              type: scanToteErrorMessage
                ? AlertMessageTypes.Error
                : AlertMessageTypes.Info,
            }}
            successMessage={
              packingState.lastSuccessfulOrderId
                ? {
                    type: AlertMessageTypes.Success,
                    textElement: (
                      <Text
                        variant="body2"
                        weight="regular"
                        margin={0}
                        tag="span"
                      >
                        Order #{packingState.lastSuccessfulOrderId} is complete.
                      </Text>
                    ),
                  }
                : undefined
            }
            scanToteHandler={scanBarcodeAtPackingStationHandler}
          />
        );
      case 'CLOSED':
        return (
          <StartStation
            stationHeaderText={`Packing Station ${stationId}`}
            startStationButtonText="Start packing"
            handleOpenStationClick={startPackingHandler}
          />
        );
      case 'CHECKING': {
        if (packingState.picklist) {
          const picklist = packingState.picklist;
          const items = getItemsFromPicklist(picklist);

          return (
            <CheckItems
              items={items}
              orderNumber={packingState.fulfilmentOrderId || ''}
              recipient={packingState.recipientName || ''}
              onCannotPackOrderBtnClick={onTroubleshootModalOpen}
              onComplete={checkPackingToteHandler}
              merchantName={packingState.merchantName}
            />
          );
        } else {
          return <>Oops! Unknown Error occured</>;
        }
      }
      case 'SELECTING_PARCEL': {
        return (
          <SelectParcel
            onCannotPackOrderBtnClick={onTroubleshootModalOpen}
            onSelect={handleSelectParcel}
            orderNumber={packingState.fulfilmentOrderId || ''}
            recipient={packingState.recipientName || ''}
            merchantName={packingState.merchantName}
            tags={packingState.tags}
            isInternationalShipment={!!packingState.isInternationalShipment}
            initialParcelCount={packingState.parcelCount}
          />
        );
      }
      case 'PARCEL_SORTING':
      case 'LABEL_PRINTING_NETWORK':
      case 'PACKING_AND_LABEL_PRINTING': {
        const { picklist } = packingState;
        const parcelType = getParcelTypeFromOrderTags(packingState.tags);
        if (picklist && packingState.parcelSize) {
          const items = getItemsFromPicklist(picklist);
          return (
            <PackAndPrint
              items={items}
              orderNumber={packingState.fulfilmentOrderId || ''}
              recipient={packingState.recipientName || ''}
              parcelSize={packingState.parcelSize}
              parcelCount={packingState.parcelCount || 1}
              parcelType={parcelType}
              printStatus={printStatus}
              labelStatus={getLabelStatus(packingState)}
              showULDModal={packingState.status === 'PARCEL_SORTING'}
              handleTransferParcelToTroubleshoot={
                handleTransferParcelToTroubleshoot
              }
              isTroubleshootPrintingViaNetwork={
                isTroubleshootPrintingViaNetwork
              }
              setIsTroubleshootPrintingViaNetwork={
                setIsTroubleshootPrintingViaNetwork
              }
              showDisposeLabelModal={isDisposeOldLabelModalOpen}
              onLabelDisposed={handleDisposeLabel}
              cancelDisposeLabelModal={handleCloseDisposeOldLabelModal}
              changeParcelTypeHandler={handleChangeParcelType}
              onComplete={handlePackOrder}
              printLabelHandler={handleStartLabelPrinting}
              printLabelViaNetworkHandler={handlePrintLabelViaNetwork}
              printTroubleshootLabelHandler={handleGenerateTroubleshootLabel}
              printTroubleshootLabelViaNetworkHandler={
                handlePrintTroubleshootLabelViaNetwork
              }
              cancelULDModal={hideULDModal}
              sortParcelHandler={handleSortParcel}
              onCannotPackOrderBtnClick={onTroubleshootModalOpen}
              merchantName={packingState.merchantName}
              isNetworkPrinting={status === 'LABEL_PRINTING_NETWORK'}
              packingNote={packingState.packingNote}
              isInsertRequired={packingState?.isInsertRequired}
              insertStatus={packingState?.insertStatus}
              addInsertHandler={addInsert}
              skipInsertHandler={skipInsert}
              packAndPrintError={packAndPrintError}
            />
          );
        } else {
          return <>Oops! Unknown Error occured</>;
        }
      }

      case 'LABEL_PRINTING': {
        const { labelPaths } = packingState;
        if (labelPaths) {
          return (
            <LabelPrinting
              labelUrl={labelPaths.pdf}
              onBackButtonClick={handleCompleteLabelPrinting}
            />
          );
        } else {
          return <>Oops! Label path is missing!</>;
        }
      }

      // TODO(FCG-453): mark as considering to refactor the legacy TS label printing to use the new one instead
      case 'TROUBLESHOOT_LABEL_COMPLETED':
      case 'TROUBLESHOOT_LABEL_PRINTING': {
        const { troubleshootLabelPath } = packingState;
        if (troubleshootLabelPath) {
          return (
            <TroubleshootLabelPrinting
              labelUrl={troubleshootLabelPath.pdf}
              printingCompleteButtonClick={handlePrintTroubleshootLabel}
              showTranferModal={
                packingState.status === 'TROUBLESHOOT_LABEL_COMPLETED'
              }
              handlerParcelTransfer={handleTransferParcelToTroubleshoot}
              revertToTroubleshootLabel={handleRevertTroubleshootLabel}
            />
          );
        } else {
          return <>Oops! Troubleshoot label path is missing!</>;
        }
      }

      default:
        return isNever(status);
    }
  };

  if (!currentWarehouse) {
    return (
      <div data-testId="warehouse-not-found-label">
        Warehouse does not exist
      </div>
    );
  }

  return (
    <>
      <Layout
        type="STATION"
        header={
          packingState.status !== 'CLOSED' && (
            <StationHeader
              stationHeaderTitle={`Packing Station ${packingState.stationId}`}
              closeStation={
                packingState.status === 'OPEN' ? closeStation : undefined
              }
            />
          )
        }
      >
        {getScreenFromStatus(packingState.status)}
      </Layout>
      <CannotPackOrderModal
        isOpen={isTroubleshootModalOpen}
        onCancel={onTroubleshootModalClose}
        onTroubleshotLabelPrint={onPackingCancel}
      />
      <PackingTroubleshootLabelPrintingModal
        open={isPrintingPackingTroubleshootLabel}
      />
      <ForceFinishPackingTroubleshootModal
        isOpen={isForceFinishPackingTroubleshootModalOpen}
        fulfilmentOrderId={packingState.fulfilmentOrderId || ''}
        picklistId={packingState.picklistId || ''}
        onClose={onForceFinishPackingTroubleshoot}
      />
    </>
  );
}
