"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PACK_AND_DISPATCH_SOCKET_EVENT_NAMES = exports.PackAndDispatchEvent = exports.packingBlindCountQuantityCountFailedEventSchema = exports.packingBlindCountQuantityCountedEventSchema = exports.packingTroubleshootForceFinishFailedEventSchema = exports.packingTroubleshootForceFinishedEventSchema = exports.packingTroubleshootLabelScannedEventSchema = exports.packingTroubleshootLabelPrintingFailedSchema = exports.printingPackingTroubleshootLabelFinishedSchema = exports.cancelOrderPackingFailedSchema = exports.orderPackingCancelledSchema = exports.toteSentToTroubleshootingFromCmcSchema = exports.cmcGenesysParcelTransferredToTroubleshoot = exports.releaseChannelResetOnPackingStationSchema = exports.releaseChannelAssignedToPackingStationSchema = exports.shippingLabelReceivedSchema = exports.troubleshootLabelStateRevertedSchema = exports.parcelTransferredToTroubleshootSchema = exports.troubleshootLabelPrintedSchema = exports.troubleshootLabelGeneratedSchema = exports.labelCreationFailedSchema = exports.orderPackingCompletionFailedSchema = exports.orderPackedSchema = exports.parcelSortingCancelledSchema = exports.parcelSortedSchema = exports.OrderPackingFailed = exports.insertSkippedSchema = exports.insertAddedSchema = exports.parcelTypeChangedSchema = exports.orderPackingCompletedSchema = exports.troubleshootLabelPrintingFailedSchema = exports.troubleshootLabelPrintingStartedSchema = exports.labelPrintingFailedSchema = exports.labelPrintingCompletedSchema = exports.labelPrintingStartedSchema = exports.parcelTypeSelectedEventSchema = exports.packingToteCheckCompletedEventSchema = exports.packingToteCheckFailedEventSchema = exports.packingToteScanFailedEventSchema = exports.packingToteScannedEventSchema = exports.packingStationClosedEventSchema = exports.fulfilmentOrderLabelNotPrinted = exports.insertOptionNotSelectedReasonSchema = exports.packingStationOpenedEventSchema = void 0;
const z = __importStar(require("zod"));
const packAndDispatch_1 = require("../commands/outcomes/packAndDispatch");
const model_1 = require("../model");
const featureFlags_1 = require("../model/featureFlags");
const base_1 = require("./base");
exports.packingStationOpenedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_STATION_OPENED'),
    payload: z.object({
        stationId: z.number(),
    }),
});
exports.insertOptionNotSelectedReasonSchema = z.literal('INSERT_OPTION_NOT_SELECTED');
exports.fulfilmentOrderLabelNotPrinted = z.literal('FULFILMENT_ORDER_LABEL_NOT_PRINTED');
exports.packingStationClosedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_STATION_CLOSED'),
    payload: z.object({
        stationId: z.number(),
    }),
});
exports.packingToteScannedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TOTE_SCANNED'),
    payload: z.object({
        stationId: z.number(),
        toteId: z.string(),
        recipientName: z.string().optional(),
        picklistId: base_1.StringId.optional(),
        orderId: base_1.StringId.optional(),
        merchantId: base_1.StringId.optional(),
        tags: z.array(z.string()).optional(),
    }),
});
exports.packingToteScanFailedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TOTE_SCAN_FAILED'),
    payload: z.object({
        stationId: z.number(),
        toteId: z.string(),
    }),
});
exports.packingToteCheckFailedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TOTE_CHECK_FAILED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.packingToteCheckCompletedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TOTE_CHECK_COMPLETED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
        toteId: z.string().optional(),
    }),
});
exports.parcelTypeSelectedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PARCEL_TYPE_SELECTED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
        parcelSize: model_1.parcelSizeSchema.optional(),
        size: z.object({
            widthMm: z.number(),
            lengthMm: z.number(),
            heightMm: z.number(),
            emptyWeightGrams: z.number().optional(),
        }),
        parcelCount: model_1.parcelCountSchema,
    }),
});
exports.labelPrintingStartedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('LABEL_PRINTING_STARTED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
        labelPath: z.string().optional(),
        isNetworkPrinting: z.boolean().optional(),
    }),
});
exports.labelPrintingCompletedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('LABEL_PRINTING_COMPLETED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.labelPrintingFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('LABEL_PRINTING_FAILED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
        reason: z
            .union([
            exports.insertOptionNotSelectedReasonSchema,
            z.literal('ENQUEUE_PRINTING_JOB_FAILED'),
        ])
            .optional(),
    }),
});
exports.troubleshootLabelPrintingStartedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TROUBLESHOOT_LABEL_PRINTING_STARTED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.troubleshootLabelPrintingFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TROUBLESHOOT_LABEL_PRINTING_FAILED'),
    payload: z.object({
        stationId: z.number().optional(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.orderPackingCompletedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('ORDER_PACKING_COMPLETED'),
    payload: z.object({
        stationId: z.number(),
        toteId: z.string().optional(),
        fulfilmentOrderId: z.string(),
        items: z.array(z.object({
            barcode: z.string(),
            quantityPacked: z.number(),
        })),
    }),
});
exports.parcelTypeChangedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PARCEL_TYPE_CHANGED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
        toteId: z.string(),
    }),
});
exports.insertAddedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('INSERT_ADDED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.insertSkippedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('INSERT_SKIPPED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.OrderPackingFailed = base_1.eventBaseSchema.extend({
    type: z.literal('ORDER_PACKING_FAILED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
        reason: exports.insertOptionNotSelectedReasonSchema.optional(),
    }),
});
exports.parcelSortedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PARCEL_SORTED'),
    payload: z.object({
        stationId: z.number(),
        orderId: base_1.StringId,
    }),
});
exports.parcelSortingCancelledSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PARCEL_SORTING_CANCELLED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.orderPackedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('ORDER_PACKED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
        toteId: base_1.StringId,
    }),
});
exports.orderPackingCompletionFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('ORDER_PACKING_COMPLETION_FAILED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
        reason: z
            .union([
            exports.insertOptionNotSelectedReasonSchema,
            exports.fulfilmentOrderLabelNotPrinted,
        ])
            .optional(),
    }),
});
exports.labelCreationFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('LABEL_CREATION_FAILED'),
    payload: z.object({
        fulfilmentOrderId: z.string(),
    }),
});
exports.troubleshootLabelGeneratedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TROUBLESHOOT_LABEL_GENERATED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string(),
        labelPaths: base_1.LabelPaths,
    }),
});
exports.troubleshootLabelPrintedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TROUBLESHOOT_LABEL_PRINTED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string(),
    }),
});
exports.parcelTransferredToTroubleshootSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PARCEL_TRANSFERRED_TO_TROUBLESHOOT'),
    payload: z.object({
        stationId: z.number(),
        orderId: base_1.StringId,
        toteId: z.string(),
    }),
});
exports.troubleshootLabelStateRevertedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TROUBLESHOOT_LABEL_STATE_REVERTED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.shippingLabelReceivedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('SHIPPING_LABEL_RECEIVED'),
    payload: z.object({
        serviceCode: z.string(),
        carrierCode: z.string().optional(),
        carrier: z.string(),
        labelPaths: base_1.LabelPaths,
        fulfilmentOrderId: z.string(),
        trackingNumber: z.string().optional(),
    }),
});
exports.releaseChannelAssignedToPackingStationSchema = base_1.eventBaseSchema.extend({
    type: z.literal('RELEASE_CHANNEL_ASSIGNED_TO_PACKING_STATION'),
    payload: z.object({
        stationId: z.number(),
        releaseChannel: featureFlags_1.releaseChannel,
    }),
});
exports.releaseChannelResetOnPackingStationSchema = base_1.eventBaseSchema.extend({
    type: z.literal('RELEASE_CHANNEL_RESET_ON_PACKING_STATION'),
    payload: z.object({
        stationId: z.number(),
    }),
});
const cmcGenesysTroubleshootReasonSchema = z.union([
    z.literal('CMC_GENESYS_FAILED_TO_CREATE_BOX'),
    z.literal('CMC_GENESYS_FAILED_TO_PRINT_LABEL'),
    z.literal('CMC_GENESYS_ITEM_NOT_SUITABLE'),
    z.literal('CMC_GENESYS_WRONG_BARCODE'),
    z.literal('CMC_GENESYS_OTHER'),
]);
exports.cmcGenesysParcelTransferredToTroubleshoot = base_1.eventBaseSchema.extend({
    type: z.literal('CMC_GENESYS_PARCEL_TRANSFERRED_TO_TROUBLESHOOT'),
    payload: z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: z.string(),
        troubleshootReason: cmcGenesysTroubleshootReasonSchema,
    }),
});
exports.toteSentToTroubleshootingFromCmcSchema = base_1.eventBaseSchema.extend({
    type: z.literal('TOTE_SENT_TO_TROUBLESHOOTING_FROM_CMC'),
    payload: z.object({
        toteId: z.string().min(1),
    }),
});
exports.orderPackingCancelledSchema = base_1.eventBaseSchema.extend({
    type: z.literal('ORDER_PACKING_CANCELLED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string(),
        picklistId: z.string(),
        toteId: z.string().optional(),
        reasons: z.array(model_1.packingErrorReasonSchema),
    }),
});
exports.cancelOrderPackingFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('CANCEL_ORDER_PACKING_FAILED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string().optional(),
        toteId: z.string().optional(),
        reason: packAndDispatch_1.cancelOrderPackingFailedOutcomeSchema,
    }),
});
exports.printingPackingTroubleshootLabelFinishedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PRINTING_PACKING_TROUBLESHOOT_LABEL_FINISHED'),
    payload: z.object({
        stationId: z.number(),
        picklistId: z.string().optional(),
        fulfilmentOrderId: z.string(),
    }),
});
exports.packingTroubleshootLabelPrintingFailedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TROUBLESHOOT_LABEL_PRINTING_FAILED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string(),
        picklistId: z.string().optional(),
        reason: model_1.labelPrintingFailureReasonsSchema,
    }),
});
exports.packingTroubleshootLabelScannedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TROUBLESHOOT_LABEL_SCANNED'),
    payload: z.object({
        stationId: z.number(),
        recipientName: z.string().optional(),
        picklistId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        merchantId: base_1.StringId.optional(),
        tags: z.array(z.string()).optional(),
    }),
});
exports.packingTroubleshootForceFinishedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TROUBLESHOOT_FORCE_FINISHED'),
    payload: z.object({
        stationId: z.number(),
        picklistId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.packingTroubleshootForceFinishFailedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_TROUBLESHOOT_FORCE_FINISH_FAILED'),
    payload: z.object({
        stationId: z.number(),
        picklistId: base_1.StringId.optional(),
        fulfilmentOrderId: base_1.StringId.optional(),
    }),
});
exports.packingBlindCountQuantityCountedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_BLIND_COUNT_QUANTITY_COUNTED'),
    payload: z.object({
        stationId: z.number(),
        toteId: base_1.StringId,
        picklistId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        blindCountTotalItemQuantity: z.number(),
    }),
});
exports.packingBlindCountQuantityCountFailedEventSchema = base_1.eventBaseSchema.extend({
    type: z.literal('PACKING_BLIND_COUNT_QUANTITY_COUNT_FAILED'),
    payload: z.object({
        stationId: z.number(),
        toteId: base_1.StringId.optional(),
        picklistId: base_1.StringId.optional(),
        fulfilmentOrderId: base_1.StringId.optional(),
        blindCountTotalItemQuantity: z.number().optional(),
    }),
});
exports.PackAndDispatchEvent = z.union([
    exports.packingStationClosedEventSchema,
    exports.packingStationOpenedEventSchema,
    exports.packingToteScannedEventSchema,
    exports.packingToteScanFailedEventSchema,
    exports.packingToteCheckCompletedEventSchema,
    exports.packingToteCheckFailedEventSchema,
    exports.parcelTypeSelectedEventSchema,
    exports.labelPrintingStartedSchema,
    exports.labelPrintingFailedSchema,
    exports.labelPrintingCompletedSchema,
    exports.orderPackingCompletedSchema,
    exports.parcelTypeChangedSchema,
    exports.OrderPackingFailed,
    exports.parcelSortedSchema,
    exports.orderPackedSchema,
    exports.labelCreationFailedSchema,
    exports.parcelSortingCancelledSchema,
    exports.troubleshootLabelGeneratedSchema,
    exports.troubleshootLabelPrintedSchema,
    exports.parcelTransferredToTroubleshootSchema,
    exports.troubleshootLabelStateRevertedSchema,
    exports.shippingLabelReceivedSchema,
    exports.troubleshootLabelPrintingStartedSchema,
    exports.troubleshootLabelPrintingFailedSchema,
    exports.releaseChannelAssignedToPackingStationSchema,
    exports.releaseChannelResetOnPackingStationSchema,
    exports.insertAddedSchema,
    exports.insertSkippedSchema,
    exports.orderPackingCompletionFailedSchema,
    exports.orderPackingCancelledSchema,
    exports.cancelOrderPackingFailedSchema,
    exports.printingPackingTroubleshootLabelFinishedSchema,
    exports.packingTroubleshootLabelPrintingFailedSchema,
    exports.packingTroubleshootLabelScannedEventSchema,
    exports.packingTroubleshootForceFinishedEventSchema,
    exports.packingTroubleshootForceFinishFailedEventSchema,
    exports.packingBlindCountQuantityCountedEventSchema,
    exports.packingBlindCountQuantityCountFailedEventSchema,
]);
exports.PACK_AND_DISPATCH_SOCKET_EVENT_NAMES = exports.PackAndDispatchEvent.options.map((opt) => opt.shape.type.value);
