import { ReactComponent as TroubleshootSVG } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { ModalHeaderProps } from '../../../../components/warehouse/Modal/Modal';
import { Text } from '../../../../components/warehouse/Text';
import { ButtonWrapper } from './DisposeLabelModal.elements';

export type DisposeLabelModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onDisposed: () => void;
};

export const DisposeLabelModal = ({
  isVisible,
  onCancel,
  onDisposed,
}: DisposeLabelModalProps): JSX.Element => {
  const header: ModalHeaderProps = {
    icon: <TroubleshootSVG />,
    text: 'Dispose old label',
  };
  return (
    <Modal
      variant="warning"
      header={header}
      isVisible={isVisible}
      actions={[
        <ButtonWrapper key="cancel-btn-wrapper">
          <Button
            variant="secondary"
            fullWidth
            onClick={onCancel}
            testId="cancel-dispose-label-button"
          >
            Cancel
          </Button>
        </ButtonWrapper>,
        <ButtonWrapper key="sort-btn-wrapper">
          <Button fullWidth onClick={onDisposed} testId="dispose-label-button">
            Confirm label disposed
          </Button>
        </ButtonWrapper>,
      ]}
    >
      <Box padding="24px">
        <Text variant="h1" weight="medium">
          A new label will be generated for the updated parcel type. Please
          dispose the previous label, and reprint and attach a new label for
          this order.
        </Text>
      </Box>
    </Modal>
  );
};
