import { PickPortStateType } from 'api-schema';
import { ReactElement, ReactNode } from 'react';
import { Button } from '../../../components/warehouse/Button';
import { Layout } from '../../../components/warehouse/Layout';
import { PickPort } from '../../../components/warehouse/PickPort';
import { PORT_CATEGORY } from '../../../constants/port';
import { AutostorePortError } from '../components/AutostorePortError/AutostorePortError';
import { CategoryPanel } from '../components/CategoryPanel';
import {
  ClosingText,
  HeaderActionWrapper,
  HeaderInformation,
} from './PickStation.elements';

interface PickStationProps {
  portState: PickPortStateType;
  closePort: () => void;
  rightPanel: ReactNode;
  modal?: ReactNode;
}

export function PickStationView(props: PickStationProps): ReactElement {
  const { portState, rightPanel, modal } = props;
  return (
    <Layout
      header={<Header {...props} />}
      danger={portState.isClosing}
      type="PORT"
    >
      <PickPort portState={portState} data-testid="pick-port" />
      {rightPanel}
      {modal}
    </Layout>
  );
}

const Header = (props: PickStationProps): ReactElement | null => {
  const { portState, closePort } = props;
  const {
    categories = [PORT_CATEGORY.STANDARD, PORT_CATEGORY.SAME_DAY],
    queuedSelectedCategories,
    pickingModes = [],
    queuedPickingModes,
  } = portState;

  return (
    <>
      <HeaderInformation>
        <span data-testid="pick-port-header">
          Picking port {portState.portId}
        </span>
        <AutostorePortError {...portState} />
      </HeaderInformation>
      {portState.isClosing ? (
        <ClosingText>Closing picking port...</ClosingText>
      ) : (
        <HeaderActionWrapper>
          <CategoryPanel
            categories={categories}
            queuedSelectedCategories={queuedSelectedCategories}
            pickingModes={pickingModes}
            queuedPickingModes={queuedPickingModes}
          />
          <Button
            variant={'tertiary'}
            size={'compact'}
            onClick={closePort}
            testId="close-port-button"
            disabled={portState.status === 'UNEXPECTED_BIN'}
          >
            Close port
          </Button>
        </HeaderActionWrapper>
      )}
    </>
  );
};
