import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { PutawayPortWithCurrentBin } from 'api-schema/lib/model/autostore-port';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { baseUrl } from '../constants/config';
import { Loadable } from '../elements/admin/Loadable';
import { useWarehouse } from '../hooks/useWarehouse';
import { PutawayTransfer } from '../screens/putawayPort/PutawayTransfer';
import { SelectReplenishmentMode } from '../screens/putawayPort/SelectReplenishmentMode/SelectReplenishmentMode';
import { useAppState } from '../store';
import { AdminThemeProvider } from '../themes/AdminTheme';
import { useNavStyles } from './styles';

type RouteParams = { warehouseId: string; portId: string };

export const PutawayPortApp = () => {
  const {
    params: { portId, warehouseId },
  } = useRouteMatch<RouteParams>();
  const [status, warehouse] = useWarehouse(warehouseId);
  const classes = useNavStyles();

  const {
    appState: { portState, featureFlags },
  } = useAppState();

  const port = portState?.type === 'PUTAWAY' ? portState : undefined;

  return (
    <AdminThemeProvider>
      <SocketProvider
        baseUrl={baseUrl}
        portId={portId}
        warehouseId={warehouseId}
        providerType="putaway"
      >
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" noWrap>
                Putaway Port {portId}
              </Typography>
              <div style={{ marginLeft: 'auto' }}>
                <Loadable isLoading={status === 'LOADING'}>
                  <Typography>{warehouse?.name}</Typography>
                </Loadable>
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.content}>
            {getPageContent(port, featureFlags)}
          </div>
        </div>
      </SocketProvider>
    </AdminThemeProvider>
  );
};

const getPageContent = (
  putawayPort: PutawayPortWithCurrentBin | undefined,
  featureFlags: FeatureFlags | undefined
) => {
  if (!putawayPort) {
    return (
      <div style={{ display: 'inline-block', textAlign: 'center' }}>
        <Loadable isLoading={!putawayPort} />
      </div>
    );
  }
  const selectReplenishmentModeEnabled = featureFlags?.showReturnsRestocking;
  if (!selectReplenishmentModeEnabled) {
    return <PutawayTransfer />;
  }
  if (!putawayPort.replenishmentMode) {
    return <SelectReplenishmentMode />;
  }
  if (putawayPort.replenishmentMode === 'STOCK_TRANSFER') {
    return <PutawayTransfer />;
  }
  if (putawayPort.replenishmentMode === 'RETURNS_RESTOCKING') {
    return '🚧 RETURNS RESTOCKING 🚧';
  }
};
