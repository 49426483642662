"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelPaths = exports.autostoreTaskIdSchema = exports.autostoreBinIdSchema = exports.portIdSchema = exports.StringId = exports.eventBaseSchema = void 0;
const zod_1 = require("zod");
const date_1 = require("./date");
exports.eventBaseSchema = zod_1.z.object({
    id: zod_1.z.number().int(),
    type: zod_1.z.string(),
    payload: zod_1.z.unknown().optional(),
    version: zod_1.z.number().int().positive().default(1),
    created_at: date_1.dateSchema,
    warehouseId: zod_1.z.string(),
    portId: zod_1.z.number().int().positive().optional(),
    fulfilmentOrderIds: zod_1.z.array(zod_1.z.string()).optional(),
});
exports.StringId = zod_1.z.string().min(1);
exports.portIdSchema = zod_1.z.number().int().positive();
exports.autostoreBinIdSchema = zod_1.z.number().int().positive().gte(10000);
exports.autostoreTaskIdSchema = zod_1.z.number().int().positive().gte(10000);
exports.LabelPaths = zod_1.z.object({
    pdf: zod_1.z.string(),
    zpl: zod_1.z.string().optional(),
});
