import { ParcelSize } from 'api-schema/lib';
import { ManualPackingCancelReason } from 'api-schema/lib/model/fulfilmentOrder';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePackAndDispatchSocket } from '../../components/common/SocketProvider';
import { snackbarErrorConfig } from '../../constants/snackbarDefaults';
import { useAppState } from '../../store';
import { mapCommandErrorToUserMessage } from '../../utils/commands';
import { PackAndPrintErrorType } from './components/PackAndPrint/PackAndPrint.elements';
import { mapPackingErrorToHumanMessage } from './methods/mapPackingErrorToHumanMessage';
import { StatusType } from './PackAndDispatch.model';
import { PackAndDispatchStationView } from './PackAndDispatch.view';

type RouteParams = { warehouseId: string; stationId: string };

export const PackAndDispatchStation = () => {
  const {
    appState: { packingState, featureFlags },
  } = useAppState();
  const {
    params: { stationId },
  } = useRouteMatch<RouteParams>();

  const { dispatchCommand, subscribeToEvents } = usePackAndDispatchSocket();
  const { enqueueSnackbar } = useSnackbar();

  const [scanToteError, setScanToteError] = useState<string | undefined>(
    undefined
  );
  const [
    isPrintingPackingTroubleshootLabel,
    setIsPrintingPackingTroubleshootingLabel,
  ] = useState(!!packingState?.isPrintingTroubleshootLabel);

  const subscribeToEventsRef = useRef(subscribeToEvents);
  const enqueueSnackbarRef = useRef(enqueueSnackbar);

  useEffect(() => {
    subscribeToEventsRef.current((event) => {
      if (event.type === 'CANCEL_ORDER_PACKING_FAILED') {
        enqueueSnackbarRef.current(
          mapPackingErrorToHumanMessage(event.payload.reason),
          snackbarErrorConfig
        );
        setIsPrintingPackingTroubleshootingLabel(false);
      }
    });
  }, [subscribeToEventsRef, enqueueSnackbarRef]);

  useEffect(() => {
    setIsPrintingPackingTroubleshootingLabel(
      !!packingState?.isPrintingTroubleshootLabel
    );
  }, [packingState?.isPrintingTroubleshootLabel]);

  const [
    isTroubleshootPrintingViaNetwork,
    setIsTroubleshootPrintingViaNetwork,
  ] = useState<boolean>(false);
  const [printStatus, setPrintStatus] = useState<StatusType>('INITIAL');
  const [isTroubleshootModalOpen, setIsTroubleshootModalOpen] = useState(false);
  const [isDisposeOldLabelModalOpen, setIsDisposeOldLabelModalOpen] =
    useState(false);

  const getPackAndPrintError = (): PackAndPrintErrorType => {
    if (packingState?.hasInsertSelectedError) {
      return 'INSERT_OPTION_NOT_SELECTED';
    }
    if (packingState?.hasLabelNotPrintedError) {
      return 'INCOMPLETE_PACKING';
    }
    return null;
  };

  const startPackingHandler = async () => {
    await dispatchCommand({
      type: 'OPEN_PACK_STATION',
    });
  };

  const scanBarcodeAtPackingStationHandler = async (barcode: string) => {
    if (barcode.length) {
      const { result } = await dispatchCommand({
        type: 'SCAN_BARCODE_AT_PACKING_STATION',
        barcode,
      });

      if (result.outcome === 'SUCCESS') {
        setScanToteError(undefined);
      } else {
        setScanToteError(mapCommandErrorToUserMessage(result));
      }
    }
  };

  const closeStationHandler = async () => {
    await dispatchCommand({
      type: 'CLOSE_PACK_STATION',
    });
  };

  const checkPackingToteHandler = async () => {
    await dispatchCommand({
      type: 'CHECK_PACKING_TOTE',
    });
  };

  const handleSelectParcel = async ({
    parcelSize,
    parcelCount,
  }: {
    parcelSize: ParcelSize;
    parcelCount: number;
  }) => {
    await dispatchCommand({
      type: 'SELECT_PARCEL_TYPE',
      parcelSize,
      parcelCount,
    });
  };

  const handleStartLabelPrinting = async () => {
    const { result } = await dispatchCommand({
      type: 'START_LABEL_PRINTING',
    });
    if (result.outcome !== 'SUCCESS') {
      setPrintStatus('ERROR');
    }
  };

  const handlePrintLabelViaNetwork = async () => {
    if (!packingState?.fulfilmentOrderId) {
      return;
    }
    const { result } = await dispatchCommand({
      type: 'PRINT_LABEL_VIA_NETWORK',
      fulfilmentOrderId: packingState.fulfilmentOrderId,
    });

    if (result && result.outcome !== 'SUCCESS') {
      if (result.outcome === 'INSERT_OPTION_NOT_SELECTED') {
        return;
      }
      return setPrintStatus('ERROR');
    }

    setPrintStatus('SUCCESS');
  };

  const handleCompleteLabelPrinting = async () => {
    const { result } = await dispatchCommand({
      type: 'COMPLETE_LABEL_PRINTING',
    });
    if (result.outcome === 'SUCCESS') {
      setPrintStatus('SUCCESS');
    }
  };

  const handleGenerateTroubleshootLabel = async () => {
    await dispatchCommand({
      type: 'GENERATE_TROUBLESHOOT_LABEL',
    });
  };

  const handlePrintTroubleshootLabelViaNetwork = async () => {
    setIsTroubleshootPrintingViaNetwork(true);
    if (!packingState?.fulfilmentOrderId) {
      return;
    }

    await dispatchCommand({
      type: 'PRINT_TROUBLESHOOT_LABEL_VIA_NETWORK',
      fulfilmentOrderId: packingState.fulfilmentOrderId,
    });
  };

  const handlePrintTroubleshootLabel = async () => {
    await dispatchCommand({
      type: 'PRINT_TROUBLESHOOT_LABEL',
    });
  };

  const handleCloseDisposeOldLabelModal = () => {
    setIsDisposeOldLabelModalOpen(false);
  };

  const handleDisposeLabel = async () => {
    await dispatchCommand({
      type: 'CHANGE_PARCEL_SIZE',
    });
    setPrintStatus('INITIAL');
    setIsDisposeOldLabelModalOpen(false);
  };

  const handleChangeParcelType = async () => {
    if (printStatus === 'SUCCESS') {
      setIsDisposeOldLabelModalOpen(true);
    } else {
      await dispatchCommand({
        type: 'CHANGE_PARCEL_SIZE',
      });
    }
  };

  const hideULDModal = async () => {
    await dispatchCommand({
      type: 'CANCEL_PARCEL_SORTING',
    });
  };

  const handlePackOrder = async () => {
    await dispatchCommand({
      type: 'PACK_ORDER',
    });
  };

  const handleSortParcel = async () => {
    setPrintStatus('INITIAL');
    await dispatchCommand({
      type: 'SORT_PARCEL',
    });
  };

  const handleTransferParcelToTroubleshoot = async () => {
    await dispatchCommand({
      type: 'TRANSFER_PARCEL_TO_TROUBLESHOOT',
    });
  };

  const handleRevertTroubleshootLabel = async () => {
    await dispatchCommand({
      type: 'REVERT_TO_TROUBLESHOOT_LABEL',
    });
  };

  const addInsert = async () => {
    await dispatchCommand({
      type: 'ADD_INSERT',
    });
  };

  const skipInsert = async () => {
    await dispatchCommand({
      type: 'SKIP_INSERT',
    });
  };

  const handleOpenTroubleshootModal = () => {
    setIsTroubleshootModalOpen(true);
  };

  const handleCloseTroubleshootModal = () => {
    setIsTroubleshootModalOpen(false);
  };

  const handleCancelPacking = async (reasons: ManualPackingCancelReason[]) => {
    dispatchCommand({
      type: 'CANCEL_PACKING_ORDER',
      reasons,
    });
    handleCloseTroubleshootModal();
    setIsPrintingPackingTroubleshootingLabel(true);
  };

  const handleForceFinishPackingTroubleshoot = () => {
    dispatchCommand({
      type: 'FORCE_FINISH_PACKING_TROUBLESHOOT',
    });
  };

  if (packingState) {
    return (
      <PackAndDispatchStationView
        stationId={stationId}
        packingState={packingState}
        printStatus={printStatus}
        scanToteErrorMessage={scanToteError}
        isTroubleshootPrintingViaNetwork={isTroubleshootPrintingViaNetwork}
        featureFlags={featureFlags || {}}
        setIsTroubleshootPrintingViaNetwork={
          setIsTroubleshootPrintingViaNetwork
        }
        handleDisposeLabel={handleDisposeLabel}
        isDisposeOldLabelModalOpen={isDisposeOldLabelModalOpen}
        handleCloseDisposeOldLabelModal={handleCloseDisposeOldLabelModal}
        closeStation={closeStationHandler}
        startPackingHandler={startPackingHandler}
        scanBarcodeAtPackingStationHandler={scanBarcodeAtPackingStationHandler}
        checkPackingToteHandler={checkPackingToteHandler}
        handleSelectParcel={handleSelectParcel}
        handleStartLabelPrinting={handleStartLabelPrinting}
        handlePrintLabelViaNetwork={handlePrintLabelViaNetwork}
        handlePrintTroubleshootLabel={handlePrintTroubleshootLabel}
        handleChangeParcelType={handleChangeParcelType}
        handlePackOrder={handlePackOrder}
        hideULDModal={hideULDModal}
        handleSortParcel={handleSortParcel}
        handleCompleteLabelPrinting={handleCompleteLabelPrinting}
        handleGenerateTroubleshootLabel={handleGenerateTroubleshootLabel}
        handlePrintTroubleshootLabelViaNetwork={
          handlePrintTroubleshootLabelViaNetwork
        }
        handleTransferParcelToTroubleshoot={handleTransferParcelToTroubleshoot}
        handleRevertTroubleshootLabel={handleRevertTroubleshootLabel}
        addInsert={addInsert}
        skipInsert={skipInsert}
        packAndPrintError={getPackAndPrintError()}
        isTroubleshootModalOpen={isTroubleshootModalOpen}
        onPackingCancel={handleCancelPacking}
        onTroubleshootModalOpen={handleOpenTroubleshootModal}
        onTroubleshootModalClose={handleCloseTroubleshootModal}
        isPrintingPackingTroubleshootLabel={isPrintingPackingTroubleshootLabel}
        isForceFinishPackingTroubleshootModalOpen={
          !!packingState?.isTroubleshooting &&
          !packingState?.isPrintingTroubleshootLabel
        }
        onForceFinishPackingTroubleshoot={handleForceFinishPackingTroubleshoot}
      />
    );
  } else {
    return <>Oops! Packing station doesn't exist</>;
  }
};
