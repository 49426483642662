import { Container } from '@material-ui/core';
import { PutawayPort } from 'api-schema/lib/model/autostore-port';
import styled from 'styled-components';
import { TileButton } from '../../../components/warehouse/TileButton/TileButton';
import { ReactComponent as PutawayReturnedStockSvg } from './images/putawayReturnedStock.svg';
import { ReactComponent as PutawayStockTransferSvg } from './images/putawayStockTransfer.svg';

export type SelectReplenishmentModeViewProps = {
  onSelectReplenishmentMode: (
    replenishmentMode: Exclude<PutawayPort['replenishmentMode'], undefined>
  ) => void;
};
export const SelectReplenishmentModeView = ({
  onSelectReplenishmentMode,
}: SelectReplenishmentModeViewProps) => {
  return (
    <Container
      title="Select replenishment mode"
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <StyledTileButtonBox>
        <TileButton
          key="STOCK_TRANSFER"
          text="Put away stock transfer"
          svg={<PutawayStockTransferSvg />}
          onClick={() => onSelectReplenishmentMode('STOCK_TRANSFER')}
        />
      </StyledTileButtonBox>
      <StyledTileButtonBox>
        <TileButton
          key="RETURNS_RESTOCKING"
          text="Put away returned stock"
          svg={<PutawayReturnedStockSvg />}
          onClick={() => onSelectReplenishmentMode('RETURNS_RESTOCKING')}
        />
      </StyledTileButtonBox>
    </Container>
  );
};

const StyledTileButtonBox = styled.div`
  height: 30vh;
`;
